import { Box, Text } from "@chakra-ui/react";
import { useGetResultsQuery } from "../../apis/operational-readiness";
import { DataTable } from "../../components/DataTable";

export const OperationalReadiness = () => {
  const { data: Results, error, isLoading } = useGetResultsQuery();

  const columns = [
    {
      Header: "Repository",
      accessor: "Repo",
    },
    {
      Header: "Test Name",
      accessor: "Name",
    },
    {
      Header: "Message",
      accessor: "Error",
      Cell: ({ value }) => value || "None",
    },
    {
      Header: "Outcome",
      accessor: "Outcome",
      Cell: ({ value }) => (
        <span style={{ color: value === "pass" ? "green" : "red" }}>
          {value.toUpperCase()}
        </span>
      ),
    },
    {
      Header: "Required",
      accessor: "Required",
      Cell: ({ value }) => (value === "true" ? "Yes" : "No"),
    },
  ];

  return (
    <Box px={{ base: "4", md: "6" }} py="5">
      {isLoading ? (
        <Text>Loading...</Text>
      ) : error ? (
        <Text color="red.500">Error loading Results</Text>
      ) : (
        <DataTable
          data={Object.entries(Results.Results).flatMap(
            ([repoName, repoResults]) =>
              repoResults.map((result) => ({
                ...result,
                Repo: repoName,
              })),
          )}
          columns={columns}
          title="Operational Readiness"
          description="Global Scan Results"
          sortByField="Repository"
          sortDesc={false}
          enableRowNavigation={false}
        />
      )}
    </Box>
  );
};
